import '../styles/App.scss';

import Nav from './Nav';
import Page0 from './Page0';
import Page1 from './Page1';
import Page2 from './Page2';
import Page404 from './Page404';

import Footer from './Footer';
import texts from '../data/texts.json';
import mimimi from '../data/servicegg.json';

import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';

const App = () => {
  //
  // CONSTANTES DE ESTADO
  //
  const [languageActual, setLanguageActual] = useState(texts);
  const [counterLanguageActual, setCounterLanguageActual] = useState(1);
  const [language, setLanguage] = useState('es');
  const [languageDisabled, setLanguageDisabled] = useState('en');

  const [visibility, setVisibility] = useState('');
  const [visibilityNav, setVisibilityNav] = useState('closed');

  const [statusMenu001, setStatusMenu001] = useState('');
  const [statusMenu002, setStatusMenu002] = useState('');
  const [statusMenu003, setStatusMenu003] = useState('');

  //
  // FUNCIONES GENERALES
  //
  const changeLanguage = () => {
    if (language === 'es') setLanguage('en');
    else setLanguage('es');
    if (languageDisabled === 'en') setLanguageDisabled('es');
    else setLanguageDisabled('en');
  };

  const changeVisibilityBtn = () => {
    setVisibility('dis-hidden');
    setVisibilityNav('open');
  };

  const changeVisibilityNav = () => {
    setVisibility('');
    setVisibilityNav('closed');
  };

  const counterLanguageChanges = () => {
    setCounterLanguageActual(counterLanguageActual + 1);
    console.log('counterLanguageActual: ', counterLanguageActual);

    if (counterLanguageActual > 3 && counterLanguageActual < 5)
      setLanguageActual(mimimi);
    else if (counterLanguageActual >= 5) setCounterLanguageActual(1);
    else setLanguageActual(texts);
  };

  return (
    <>
      <Nav
        texts={languageActual.texts}
        language={language}
        languageDisabled={languageDisabled}
        changeLanguage={changeLanguage}
        counterLanguageChanges={counterLanguageChanges}
        visibility={visibility}
        visibilityNav={visibilityNav}
        setVisibility={setVisibility}
        setVisibilityNav={setVisibilityNav}
        changeVisibilityBtn={changeVisibilityBtn}
        changeVisibilityNav={changeVisibilityNav}
        statusMenu001={statusMenu001}
        statusMenu002={statusMenu002}
        statusMenu003={statusMenu003}
        setStatusMenu001={setStatusMenu001}
        setStatusMenu002={setStatusMenu002}
        setStatusMenu003={setStatusMenu003}
      />

      <Routes>
        <Route
          path='/'
          element={
            <Page0
              texts={languageActual.texts}
              language={language}
              visibility={visibility}
              visibilityNav={visibilityNav}
              setVisibility={setVisibility}
              setVisibilityNav={setVisibilityNav}
              changeVisibilityBtn={changeVisibilityBtn}
              changeVisibilityNav={changeVisibilityNav}
              statusMenu001={statusMenu001}
              statusMenu002={statusMenu002}
              statusMenu003={statusMenu003}
              setStatusMenu001={setStatusMenu001}
              setStatusMenu002={setStatusMenu002}
              setStatusMenu003={setStatusMenu003}
            />
          }
        />
        <Route
          path='/Page1'
          element={<Page1 texts={languageActual.texts} language={language} />}
        />
        <Route
          path='/Page2'
          element={<Page2 texts={languageActual.texts} language={language} />}
        />
        <Route
          path='*'
          element={<Page404 texts={languageActual.texts} language={language} />}
        />
      </Routes>

      <Footer
        texts={languageActual.texts}
        language={language}
        visibility={visibility}
        visibilityNav={visibilityNav}
        setVisibility={setVisibility}
        setVisibilityNav={setVisibilityNav}
        changeVisibilityBtn={changeVisibilityBtn}
        changeVisibilityNav={changeVisibilityNav}
        statusMenu001={statusMenu001}
        statusMenu002={statusMenu002}
        statusMenu003={statusMenu003}
        setStatusMenu001={setStatusMenu001}
        setStatusMenu002={setStatusMenu002}
        setStatusMenu003={setStatusMenu003}
      />
    </>
  );
};

export default App;
