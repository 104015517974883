import '../styles/components/Form.scss';
import '../styles/components/CtaButton.scss';
import { useState, useEffect } from 'react';

import FormNewPerson from './FormNewPerson';

const Form = (props) => {
  //
  // CONSTANTES DE ESTADO
  //
  const [counter, setCounter] = useState(1);
  const [formVisible, setFormVisible] = useState('dis-hidden');
  const [withBG, setWithBG] = useState('');

  //
  // FUNCIONES MANEJADORAS
  //
  const handleAddPeople = () => {
    setCounter(counter + 1);
  };
  const handleSubtractPeople = () => {
    if (counter > 1) setCounter(counter - 1);
  };

  //
  // HOOKS
  //
  useEffect(() => {
    if (counter > 1) {
      setFormVisible('dis-flex');
      setWithBG('withBG');
    } else {
      setFormVisible('dis-hidden');
      setWithBG('');
    }
  }, [counter]);

  // Sección del formulario que se repite al añadir a nuevas personas al grupo.
  let formNewPerson = [];
  for (let index = 0; index < counter; index++) {
    formNewPerson[index] = (
      <FormNewPerson
        texts={props.texts}
        language={props.language}
        index={index}
        formVisible={formVisible}
        withBG={withBG}
      />
    );
  }

  return (
    <section className='section-form'>
      <span className='shape-form shape-form--001'></span>
      <span className='shape-form shape-form--002'></span>
      <div className='max-container'>
        <form
          className='form'
          action='https://formspree.io/f/xyyadzrw'
          method='post'
        >
          <div className={formVisible}>
            <h5 className='title-h5'>
              {props.texts[102][props.language]}
              {counter}
            </h5>
          </div>
          <div className={formVisible}>
            <div className='form__field'>
              <label htmlFor='group' className={`paragraph ${formVisible}`}>
                {props.texts[103][props.language]}
              </label>
              <input
                className={formVisible}
                type='text'
                id='group'
                name='Nombre del grupo:'
                placeholder={props.texts[104][props.language]}
                required='required'
              />
            </div>
          </div>

          {formNewPerson}

          <p className='title-h5'>{props.texts[105][props.language]}</p>
          <p className='paragraph text-center'>
            {props.texts[106][props.language]}
          </p>
          <div className='form__add-people-wrapper'>
            <div
              className='cta-button cta-button--confirm--form left'
              onClick={handleAddPeople}
            >
              {props.texts[107][props.language]}
            </div>

            <div
              className='cta-button cta-button--confirm--form right'
              onClick={handleSubtractPeople}
            >
              {props.texts[108][props.language]}
            </div>
          </div>
          <button class='cta-button cta-button--details--form'>
            {props.texts[109][props.language]}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Form;
