import '../styles/components/TimingContent.scss';

const TimingContent = (props) => {
  return (
    <>
      <div className='timing__content'>
        <div className='timing__content__hour'>
          <p className='title-hour'>{props.hour}</p>
        </div>
        <div className='timing__content__dot'>
          <div className='dot'></div>
        </div>
        <div className='timing__content__title'>
          <h4 className='title-h4'>{props.title}</h4>
        </div>
        <div className='timing__content__text'>
          <p className='paragraph--little'>
            {props.text_p1}
            <span className='a_link'>
              <a href={props.link1} target='_blank'>
                {props.text_link1}
              </a>
            </span>
            {props.text_p2}
            <span className='a_link'>
              <a href={props.link2} target='_blank'>
                {props.text_link2}
              </a>
            </span>
            {props.text_p3}
          </p>
        </div>
        <div className='timing__content__empty-1'></div>
        <div className='timing__content__empty-2'></div>
      </div>
    </>
  );
};

export default TimingContent;
