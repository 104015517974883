import '../styles/components/HalfHeroBottom.scss';

const HalfHeroBottom = (props) => {
  return (
    <section className='section-half-hero-bottom'>
      <div className='max-container'>
        <h2 className='title-h2 white-space-jump'>{props.text_t1}</h2>
        <p className='paragraph white-space-jump'>{props.text_p1}</p>
      </div>
      <span className='shape-dot shape-dot--001'></span>
      <span className='shape-dot shape-dot--002'></span>
      <span className='shape-dot shape-dot--003'></span>
    </section>
  );
};

export default HalfHeroBottom;
