import '../styles/components/Dresscode.scss';

const Dresscode = (props) => {
  return (
    <section className='section-dresscode'>
      <div className='max-container'>
        <h2 className='title-h2'>{props.texts[36][props.language]}</h2>
        <h4 className='title-h4'>{props.texts[37][props.language]}</h4>
        <p className='paragraph'>
          {props.texts[38][props.language]}
          <span className='shape-dresscode shape-dresscode--001'></span>
        </p>
        <h4 className='title-h4'>{props.texts[39][props.language]}</h4>
        <p className='paragraph'>
          {props.texts[40][props.language]}
          <span className='shape-dresscode shape-dresscode--002'></span>
        </p>
      </div>
    </section>
  );
};

export default Dresscode;
