import '../styles/components/Footer.scss';
import CtaButtonConfirm from './CtaButtonConfirm';

const Footer = (props) => {
  return (
    <section className='section-footer'>
      <h2 className='section-footer__title-h2'>
        {props.texts[0][props.language]}
      </h2>
      <CtaButtonConfirm
        text_s1={props.texts[6][props.language]}
        visibility={props.visibility}
        visibilityNav={props.visibilityNav}
        setVisibility={props.setVisibility}
        setVisibilityNav={props.setVisibilityNav}
        changeVisibilityBtn={props.changeVisibilityBtn}
        changeVisibilityNav={props.changeVisibilityNav}
        statusMenu001={props.statusMenu001}
        statusMenu002={props.statusMenu002}
        statusMenu003={props.statusMenu003}
        setStatusMenu001={props.setStatusMenu001}
        setStatusMenu002={props.setStatusMenu002}
        setStatusMenu003={props.setStatusMenu003}
      />
    </section>
  );
};

export default Footer;
