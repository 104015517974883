import '../styles/components/Gifts.scss';

const Gifts = (props) => {
  return (
    <section className='section-gifts'>
      <div className='max-container'>
        <h2 className='title-h2'>{props.texts[41][props.language]}</h2>
        <p className='paragraph'>{props.texts[42][props.language]}</p>
      </div>
      <span className='shape-gifts shape-gifts--001'></span>
    </section>
  );
};

export default Gifts;
