import '../styles/components/Page.scss';
import HalfHero from './HalfHero';
import HalfHeroBottom from './HalfHeroBottom';
import Form from './Form';

const Page2 = (props) => {
  return (
    <section className='page'>
      <HalfHero
        texts={props.texts}
        language={props.language}
        hh_img_bg='hh_img_bg_002'
      />
      <HalfHeroBottom
        texts={props.texts}
        language={props.language}
        text_t1={props.texts[100][props.language]}
        text_p1={props.texts[101][props.language]}
      />
      <Form texts={props.texts} language={props.language} />
    </section>
  );
};

export default Page2;
