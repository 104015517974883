import '../styles/components/Nav.scss';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const Nav = (props) => {
  //
  // CONSTANTES DE ESTADO
  //

  // VARIABLES Y CONSTANTES GENERALES
  //

  //
  // HOOKS
  //
  useEffect(() => {
    let URL = window.location.hash;

    if (URL === undefined || URL === '' || URL === '#/')
      props.setStatusMenu001('selected');
    else props.setStatusMenu001('no-selected');
    if (URL === '#/Page1') props.setStatusMenu002('selected');
    else props.setStatusMenu002('no-selected');
    if (URL === '#/Page2') props.setStatusMenu003('selected');
    else props.setStatusMenu003('no-selected');

    const allowedUrls = ['', '#/', '#/Page1', '#/Page2'];
    if (!allowedUrls.includes(URL)) {
      props.setStatusMenu001('');
      props.setStatusMenu002('');
      props.setStatusMenu003('');
    }
  }, []);

  //
  // FUNCIONES MANEJADORAS
  //
  const handleChangeLanguage = () => {
    props.changeLanguage();
    props.counterLanguageChanges();
  };

  const handleClick = (ev) => {
    props.changeVisibilityNav();

    let URL = window.location.hash;

    if (URL === undefined || URL === '' || URL === '#/')
      props.setStatusMenu001('selected');
    else props.setStatusMenu001('no-selected');
    if (URL === '#/Page1') props.setStatusMenu002('selected');
    else props.setStatusMenu002('no-selected');
    if (URL === '#/Page2') props.setStatusMenu003('selected');
    else props.setStatusMenu003('no-selected');
  };

  //
  // FUNCIONES GENERALES
  //

  return (
    <section className='section-nav'>
      <div className='max-container'>
        <div className={`nav-mobile ${props.visibility}`}>
          <span>{props.texts[0][props.language]}</span>

          <div
            className={`icon-bars ${props.visibility}`}
            onClick={props.changeVisibilityBtn}
          ></div>
        </div>

        <nav className={`nav ${props.visibilityNav}`} onLoad={handleClick}>
          <ul className='nav-inicio'>
            <li id='0' onClick={handleClick}>
              <Link to='/'>{props.texts[0][props.language]}</Link>
            </li>
          </ul>
          <ul>
            <li id='0' className={props.statusMenu001} onClick={handleClick}>
              <Link to='/'>{props.texts[1][props.language]}</Link>
            </li>
            <li id='1' className={props.statusMenu002} onClick={handleClick}>
              <Link to='/Page1'>{props.texts[2][props.language]}</Link>
            </li>
            <li id='2' className={props.statusMenu003} onClick={handleClick}>
              <Link to='/Page2'>{props.texts[3][props.language]}</Link>
            </li>

            <li onClick={handleChangeLanguage}>
              <span className={`language-${props.language}`}>
                {props.texts[117][props.language]}
              </span>
              <span> / </span>
              <span className={`language-${props.languageDisabled}`}>
                {props.texts[118][props.language]}
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default Nav;
