import '../styles/components/Page.scss';
import HalfHero from './HalfHero';
import HalfHeroBottom from './HalfHeroBottom';
import WhatToDo from './WhatToDo';

const Page1 = (props) => {
  return (
    <section className='page'>
      <HalfHero
        texts={props.texts}
        language={props.language}
        hh_img_bg='hh_img_bg_001'
      />
      <HalfHeroBottom
        texts={props.texts}
        language={props.language}
        text_t1={props.texts[43][props.language]}
        text_p1={props.texts[44][props.language]}
      />
      <WhatToDo texts={props.texts} language={props.language} />
    </section>
  );
};

export default Page1;
