import '../styles/components/Date.scss';

const Date = (props) => {
  return (
    <section className='section-date '>
      <div className='max-container flex-column'>
        <h2 className='title-h2'>{props.texts[7][props.language]}</h2>
        <div className='section-date__info'>
          <div className='info'>
            <span className='icon-info icon-info--date'></span>
            <p className='paragraph'>{props.texts[8][props.language]}</p>
          </div>
          <div className='info'>
            <span className='icon-info icon-info--hour'></span>
            <p className='paragraph'>{props.texts[9][props.language]}</p>
          </div>
          <div className='info'>
            <span className='icon-info icon-info--place'></span>
            <p className='paragraph'>
              {props.texts[10][props.language]}{' '}
              <span className='a_link'>
                <a
                  href='https://www.google.com/maps/place/La+Gondola/@44.4751919,26.0925991,15z/data=!4m6!3m5!1s0x40b2026dc6f29df5:0xd68c273b42d9dc27!8m2!3d44.4751919!4d26.0925991!16s%2Fg%2F1hf93269r'
                  target='_blank'
                >
                  {props.texts[11][props.language]}
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Date;
