import '../styles/components/HalfHero.scss';

const HalfHero = (props) => {
  return (
    <section
      className={`section-half-hero hh_img_bg ${props.hh_img_bg}`}
    ></section>
  );
};

export default HalfHero;
