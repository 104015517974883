import '../styles/components/Form.scss';
import '../styles/components/CtaButton.scss';

const FormNewPerson = (props) => {
  return (
    <div className={`form-new-person ${props.withBG}`}>
      <span className={`title-h5 ${props.formVisible}`}>
        {props.texts[110][props.language]}
        {props.index + 1}
      </span>
      <div className='form__field'>
        <label htmlFor={`name${props.index + 1}`} className='paragraph'>
          {props.texts[111][props.language]}
        </label>
        <input
          type='text'
          id={`name${props.index + 1}`}
          name={`Nombre ${props.index + 1}:`}
          placeholder={props.texts[111][props.language]}
          required='required'
        />
      </div>
      <div className='form__email-phone'>
        <div className='form__field form__email-phone--email'>
          <label htmlFor={`email${props.index + 1}`} className='paragraph'>
            {props.texts[112][props.language]}
          </label>
          <input
            type='email'
            id={`email${props.index + 1}`}
            name={`Email ${props.index + 1}:`}
            placeholder={props.texts[112][props.language]}
          />
        </div>
        <div className='form__field form__email-phone--phone'>
          <label htmlFor={`tel${props.index + 1}`} className='paragraph'>
            {props.texts[113][props.language]}
          </label>
          <input
            type='tel'
            id={`tel${props.index + 1}`}
            name={`Teléfono ${props.index + 1}:`}
            placeholder={props.texts[113][props.language]}
          />
        </div>
      </div>
      <div className='form__field'>
        <label htmlFor={`message${props.index + 1}`} className='paragraph'>
          {props.texts[114][props.language]}
        </label>
        <textarea
          name={`Necesidades especiales ${props.index + 1}:`}
          id={`message${props.index + 1}`}
          placeholder={props.texts[114][props.language]}
        ></textarea>
      </div>

      <div className='form__field--checkbox'>
        <input
          className='checkbox'
          type='checkbox'
          name={`Peluquería ${props.index + 1}:`}
          id={`peluqueria${props.index + 1}`}
        />
        <label
          htmlFor={`peluqueria${props.index + 1}`}
          className='paragraph checkbox'
        >
          {props.texts[115][props.language]}
        </label>
      </div>
      <div className='form__field--checkbox'>
        <input
          className='checkbox'
          type='checkbox'
          name={`Tour ${props.index + 1}:`}
          id={`tour${props.index + 1}`}
        />
        <label
          htmlFor={`tour${props.index + 1}`}
          className='paragraph checkbox'
        >
          {props.texts[116][props.language]}
        </label>
      </div>
    </div>
  );
};

export default FormNewPerson;
