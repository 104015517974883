import '../styles/components/TimingContent.scss';

const TimingContentReverse = (props) => {
  return (
    <>
      <div className='timing__content--reverse'>
        <div className='timing__content--reverse__hour'>
          <p className='title-hour'>{props.hour}</p>
        </div>
        <div className='timing__content--reverse__dot'>
          <div className='dot'></div>
        </div>
        <div className='timing__content--reverse__title'>
          <h4 className='title-h4'>{props.title}</h4>
        </div>
        <div className='timing__content--reverse__text'>
          <p className='paragraph--little'>
            {props.text_p1}
            <span className='a_link'>{props.text_link1}</span>
            {props.text_p2}
            <span className='a_link'>{props.text_link2}</span>
            {props.text_p3}
          </p>
        </div>
        <div className='timing__content--reverse__empty-1'></div>
        <div className='timing__content--reverse__empty-2'></div>
      </div>
    </>
  );
};

export default TimingContentReverse;
