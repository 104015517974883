import '../styles/components/CtaButton.scss';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const CtaButtonConfirm = (props) => {
  useEffect(() => {
    let URL = window.location.hash;

    if (URL === undefined || URL === '' || URL === '#/')
      props.setStatusMenu001('selected');
    else props.setStatusMenu001('no-selected');
    if (URL === '#/Page1') props.setStatusMenu002('selected');
    else props.setStatusMenu002('no-selected');
    if (URL === '#/Page2') props.setStatusMenu003('selected');
    else props.setStatusMenu003('no-selected');

    const allowedUrls = ['', '#/', '#/Page1', '#/Page2'];
    if (!allowedUrls.includes(URL)) {
      props.setStatusMenu001('');
      props.setStatusMenu002('');
      props.setStatusMenu003('');
    }
  }, []);

  const handleClick = (ev) => {
    props.changeVisibilityNav();

    let URL = window.location.hash;

    if (URL === undefined || URL === '' || URL === '#/')
      props.setStatusMenu001('selected');
    else props.setStatusMenu001('no-selected');
    if (URL === '#/Page1') props.setStatusMenu002('selected');
    else props.setStatusMenu002('no-selected');
    if (URL === '#/Page2') props.setStatusMenu003('selected');
    else props.setStatusMenu003('no-selected');
  };

  return (
    <div onLoad={handleClick}>
      <Link to='/Page2' onClick={handleClick}>
        <span className='cta-button cta-button--confirm'>{props.text_s1}</span>
      </Link>
    </div>
  );
};

export default CtaButtonConfirm;
