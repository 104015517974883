import '../styles/components/Page.scss';
import Hero from './Hero';
import Date from './Date';
import Timing from './Timing';
import Dresscode from './Dresscode';
import Gifts from './Gifts';

const Page0 = (props) => {
  return (
    <section className='page' id='page0'>
      <Hero
        texts={props.texts}
        language={props.language}
        visibility={props.visibility}
        visibilityNav={props.visibilityNav}
        setVisibility={props.setVisibility}
        setVisibilityNav={props.setVisibilityNav}
        changeVisibilityBtn={props.changeVisibilityBtn}
        changeVisibilityNav={props.changeVisibilityNav}
        statusMenu001={props.statusMenu001}
        statusMenu002={props.statusMenu002}
        statusMenu003={props.statusMenu003}
        setStatusMenu001={props.setStatusMenu001}
        setStatusMenu002={props.setStatusMenu002}
        setStatusMenu003={props.setStatusMenu003}
      />
      <Date texts={props.texts} language={props.language} />
      <Timing texts={props.texts} language={props.language} />
      <Dresscode texts={props.texts} language={props.language} />
      <Gifts texts={props.texts} language={props.language} />
    </section>
  );
};

export default Page0;
