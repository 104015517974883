import '../styles/components/WhatToDo.scss';

import InRomaniaContent1 from './InRomaniaContent1';
import InRomaniaContent2 from './InRomaniaContent2';
import InRomaniaContent3 from './InRomaniaContent3';

const WhatToDo = (props) => {
  return (
    <section className='section-what-to-do'>
      <span className='shape-wtd shape-wtd--001'></span>
      <span className='shape-wtd shape-wtd--002'></span>
      <span className='shape-wtd shape-wtd--003'></span>
      <div className='max-container'>
        <InRomaniaContent1
          texts={props.texts}
          language={props.language}
          side='right'
          wtd_img_bg_class='wtd_img_bg_001'
          title_t1={props.texts[47][props.language]}
          title_t2={props.texts[48][props.language]}
          text_p1={props.texts[49][props.language]}
          title_t3={props.texts[50][props.language]}
          text_p2={props.texts[51][props.language]}
          text_p3={props.texts[52][props.language]}
          title_t4={props.texts[53][props.language]}
          text_p4={props.texts[54][props.language]}
          title_t5={props.texts[55][props.language]}
          text_p5={props.texts[56][props.language]}
          text_p6={props.texts[57][props.language]}
          text_p7={props.texts[58][props.language]}
          text_p8={props.texts[59][props.language]}
          text_p9={props.texts[60][props.language]}
          text_p10={props.texts[61][props.language]}
        />
        <InRomaniaContent2
          side='left'
          texts={props.texts}
          language={props.language}
          wtd_img_bg_class='wtd_img_bg_002'
          title_t1={props.texts[62][props.language]}
          title_t2={props.texts[63][props.language]}
          text_p1={props.texts[64][props.language]}
          title_t3={props.texts[65][props.language]}
          text_p2={props.texts[66][props.language]}
          text_p3={props.texts[67][props.language]}
          text_p4={props.texts[68][props.language]}
          text_p5={props.texts[69][props.language]}
          text_p6={props.texts[70][props.language]}
          title_t4={props.texts[71][props.language]}
          text_p7={props.texts[72][props.language]}
          text_p8={props.texts[73][props.language]}
          text_p9={props.texts[74][props.language]}
          text_p10={props.texts[75][props.language]}
          title_t5={props.texts[76][props.language]}
          text_p11={props.texts[77][props.language]}
          text_p12={props.texts[78][props.language]}
          text_p13={props.texts[79][props.language]}
          text_p14={props.texts[80][props.language]}
          text_p15={props.texts[81][props.language]}
          text_p16={props.texts[82][props.language]}
        />
        <InRomaniaContent3
          side='right'
          texts={props.texts}
          language={props.language}
          wtd_img_bg_class='wtd_img_bg_003'
          title_t1={props.texts[83][props.language]}
          title_t2={props.texts[84][props.language]}
          text_p1={props.texts[85][props.language]}
          title_t3={props.texts[86][props.language]}
          text_p2={props.texts[87][props.language]}
          text_p3={props.texts[88][props.language]}
          text_p4={props.texts[89][props.language]}
          text_p5={props.texts[90][props.language]}
          title_t4={props.texts[91][props.language]}
          text_p6={props.texts[92][props.language]}
          text_p7={props.texts[93][props.language]}
          text_p8={props.texts[94][props.language]}
          text_p9={props.texts[95][props.language]}
          text_p10={props.texts[96][props.language]}
          text_p11={props.texts[97][props.language]}
          text_p12={props.texts[98][props.language]}
          text_p13={props.texts[99][props.language]}
        />
      </div>
    </section>
  );
};

export default WhatToDo;
