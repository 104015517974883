import '../styles/components/Timing.scss';

import TimingContent from './TimingContent';
import TimingContentReverse from './TimingContentReverse';

const Timing = (props) => {
  return (
    <section className='section-timing'>
      <div className='max-container'>
        <div className='timing'>
          <TimingContentReverse
            hour={props.texts[12][props.language]}
            title={props.texts[13][props.language]}
            text_p1={props.texts[14][props.language]}
          />
          <TimingContent
            hour={props.texts[15][props.language]}
            title={props.texts[16][props.language]}
            text_p1={props.texts[17][props.language]}
          />
          <TimingContentReverse
            hour={props.texts[18][props.language]}
            title={props.texts[19][props.language]}
            text_p1={props.texts[20][props.language]}
          />
          <TimingContent
            hour={props.texts[21][props.language]}
            title={props.texts[22][props.language]}
            text_p1={props.texts[23][props.language]}
            text_link1={props.texts[24][props.language]}
            link1='https://www.youtube.com/watch?v=nlwa7EfXe3A'
            text_p2={props.texts[25][props.language]}
          />
          <TimingContentReverse
            hour={props.texts[26][props.language]}
            title={props.texts[27][props.language]}
            text_p1={props.texts[28][props.language]}
          />
          <TimingContent
            hour={props.texts[29][props.language]}
            title={props.texts[30][props.language]}
            text={props.texts[31][props.language]}
            text_p1={props.texts[31][props.language]}
            text_link1={props.texts[32][props.language]}
            link1='https://www.google.com/maps/place/Biutiful+By+The+Lake/@44.4772393,26.0868045,15z/data=!4m2!3m1!1s0x0:0x4fb2e780e68e2e95?sa=X&ved=2ahUKEwjsxIbEhbP9AhVxhf0HHVD4BxEQ_BJ6BAh8EAg'
            text_p2={props.texts[33][props.language]}
            text_link2={props.texts[34][props.language]}
            link2='https://www.google.com/maps/place/Funky+Lounge/@44.4734407,26.0856189,17z/data=!4m14!1m7!3m6!1s0x40b2026cc183530d:0x82b8a11ddef4bfdc!2sFunky+Lounge!8m2!3d44.4734407!4d26.0878076!16s%2Fg%2F11bydjrj2n!3m5!1s0x40b2026cc183530d:0x82b8a11ddef4bfdc!8m2!3d44.4734407!4d26.0878076!16s%2Fg%2F11bydjrj2n'
            text_p3={props.texts[35][props.language]}
          />
          <span className='timing__line'></span>
        </div>
      </div>
      <span className='shape-branch shape-branch--001'></span>
      <span className='shape-branch shape-branch--002'></span>
    </section>
  );
};

export default Timing;
