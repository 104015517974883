import '../styles/components/Page404.scss';

function Page404(props) {
  return (
    <section className='section-page404'>
      <h1 className='title-h1 title-h1--404'>404</h1>
    </section>
  );
}

export default Page404;
