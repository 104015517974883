import '../styles/components/InRomaniaContent.scss';
import CtaButtonDetails from './CtaButtonDetails';
import { useState, useEffect } from 'react';

const InRomaniaContent2 = (props) => {
  //
  // CONSTANTES DE ESTADO
  //
  const [visibility, setVisibility] = useState('dis-hidden');
  const [visibilitySpan, setVisibilitySpan] = useState('dis-flex');
  const [visibilityBtn, setVisibilityBtn] = useState(
    props.texts[45][props.language]
  );

  //
  // FUNCIONES
  //
  const handleClick = () => {
    changeVisibility();
    changeVisibilitySpan();
    changeVisibilityBtn();
  };

  const changeVisibility = () => {
    if (visibility === 'dis-hidden') setVisibility('dis-flex');
    else setVisibility('dis-hidden');
  };
  const changeVisibilitySpan = () => {
    if (visibilitySpan === 'dis-hidden') setVisibilitySpan('dis-flex');
    else setVisibilitySpan('dis-hidden');
  };

  const changeVisibilityBtn = () => {
    if (visibilityBtn === props.texts[45][props.language])
      setVisibilityBtn(props.texts[46][props.language]);
    else setVisibilityBtn(props.texts[45][props.language]);
  };

  useEffect(() => {
    setVisibilityBtn(props.cta_text_more);
  }, [setVisibility]);

  console.log('LANGUAGE', props.language);

  return (
    <section className={`section-in-romania-content-${props.side}`}>
      <div className={`section-in-romania-content-${props.side}__text`}>
        <h3 className='title-h3'>{props.title_t1}</h3>
        <h5 className='title-h5'>{props.title_t2}</h5>
        <p className={`paragraph white-space-jump text-${props.side}`}>
          {props.text_p1}
        </p>

        <CtaButtonDetails
          handleClick={handleClick}
          visibilitySpan={visibilitySpan}
          visibility={visibility}
          text_s1={props.texts[45][props.language]}
          text_s2={props.texts[46][props.language]}
        />

        <div className={`dropdown dropdown--${props.side} ${visibility}`}>
          <h5 className='title-h5'>{props.title_t3}</h5>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            {props.text_p2}
          </p>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            <a
              className='a_link'
              href='https://www.hotelcismigiu.ro/'
              target={'_blank'}
            >
              {props.text_p3}
            </a>
            {props.text_p4}
          </p>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            <a
              className='a_link'
              href='https://ibisstylesbucharestcenter.com/'
              target={'_blank'}
            >
              {props.text_p5}
            </a>
            {props.text_p6}
          </p>
          <h5 className='title-h5'>{props.title_t4}</h5>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            {props.text_p7}
          </p>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            <a
              className='a_link'
              href='https://www.herastrau.ro/ro/hotel'
              target={'_blank'}
            >
              {props.text_p8}
            </a>
            {props.text_p9}
            <a
              className='a_link'
              href='https://erbasu.bucharest-hotel.com/es/#main'
              target={'_blank'}
            >
              {props.text_p10}
            </a>
          </p>
          <h5 className='title-h5'>{props.title_t5}</h5>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            <a
              className='a_link'
              href='https://www.airbnb.es/a/stays/Bucharest--Bucharest--Romania?c=.pi0.pk608529337_59690470254&localized_ghost=true&gclid=Cj0KCQiAo-yfBhD_ARIsANr56g5RhOibywfedlKngVGY-Pk8Rykm-UJhw6RvYS6NLQ6CvatHGpF8-rUaAmRwEALw_wcB'
              target={'_blank'}
            >
              {props.text_p11}
            </a>
            {props.text_p12}
          </p>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            <a
              className='a_link'
              href='https://www.booking.com/city/ro/bucharest.es.html'
              target={'_blank'}
            >
              {props.text_p13}
            </a>
            {props.text_p14}
          </p>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            {props.text_p15}
          </p>
          <p className={`paragraph white-space-jump text-${props.side}`}>
            {props.text_p16}
          </p>
        </div>
      </div>
      <div className={`section-in-romania-content-${props.side}__img`}>
        <div className={`img-bg ${props.wtd_img_bg_class}`}></div>
      </div>
    </section>
  );
};

export default InRomaniaContent2;
