import '../styles/components/Hero.scss';
import CtaButtonConfirm from './CtaButtonConfirm';

const Hero = (props) => {
  return (
    <section className='section-hero'>
      <h1 className='title-h1'>{props.texts[0][props.language]}</h1>

      <h2 className='section-hero__title-h2 white-space-jump'>
        {props.texts[4][props.language]}
        <strong className='strong dis-block'>
          {props.texts[5][props.language]}
        </strong>
      </h2>

      <CtaButtonConfirm
        text_s1={props.texts[6][props.language]}
        visibility={props.visibility}
        visibilityNav={props.visibilityNav}
        setVisibility={props.setVisibility}
        setVisibilityNav={props.setVisibilityNav}
        changeVisibilityBtn={props.changeVisibilityBtn}
        changeVisibilityNav={props.changeVisibilityNav}
        statusMenu001={props.statusMenu001}
        statusMenu002={props.statusMenu002}
        statusMenu003={props.statusMenu003}
        setStatusMenu001={props.setStatusMenu001}
        setStatusMenu002={props.setStatusMenu002}
        setStatusMenu003={props.setStatusMenu003}
      />
    </section>
  );
};

export default Hero;
