import '../styles/components/CtaButton.scss';

const CtaButtonDetails = (props) => {
  return (
    <>
      <span
        className='cta-button cta-button--details'
        onClick={props.handleClick}
      >
        <span className={`${props.visibilitySpan}`}>{props.text_s1}</span>
        <span className={`${props.visibility}`}>{props.text_s2}</span>
      </span>
    </>
  );
};

export default CtaButtonDetails;
